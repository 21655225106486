// CSS FOR BACKGROUNDS
// export const ColorBackgroundGradientStart: string = `rgba(116, 1, 196, 1)`;

// export const ColorBackgroundGradientEnd: string = `rgba(0, 178, 242, 1)`;

export const ColorBackgroundGradientStart: string = `#249402`;

export const ColorBackgroundGradientEnd: string = `#4bf571`;

export const ColorBackgroundGradient: string = `linear-gradient(
  -45deg,
  ${ColorBackgroundGradientStart} 0%,
  ${ColorBackgroundGradientEnd} 100%
);`;

export const ColorButtonBackground1: string = `rgb(221, 221, 221)`;
// END BACKGROUNDS

// CSS FOR TEXT
export const ColorBaseText: string = `rgb(40,40,40)`;
// END TEXT
