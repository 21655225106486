import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Button } from '../Button/Button';
import { FadeInDiv } from '../AnimatedComponents/FadeIn';
import { withRouter, RouteComponentProps } from 'react-router-dom';

const NavBarWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: calc(100vw - 25px);
	overflow: hidden;
	height: 5vh;
	max-height: 40px;
	display: flex;
	flex-direction: row-reverse;
	padding: 5px;
	padding-left: 15px;
	padding-right: 10px;
`;

const NavItem = styled(FadeInDiv)`
	/* border: 2px solid #fff; */
	/* border-bottom: 2px solid #fff; */
	background: rgba(0, 0, 0, 0);
	padding: 5px;
	margin-right: 5px;
	height: min-content;
	color: #fff;
	font-size: unset;
	font-weight: unset;
`;

const NavBarComp = (props: RouteComponentProps): ReactElement => {
	const goToWorks = () => {
		props.history.push('/how-it-works');
	};

	return (
		<NavBarWrapper>
			{/* <NavItem animateTime={'1s'} animateDelay={'1.15s'}>
				Login
			</NavItem> */}
			{/* <NavItem animateTime={'1s'} animateDelay={'1.25s'} onClick={goToWorks}>
				How It Works
			</NavItem> */}
		</NavBarWrapper>
	);
};

export const NavBar = withRouter(NavBarComp);
