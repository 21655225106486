import React from 'react';
import styled from 'styled-components';
export const Page = styled.div`
	flex: 1;
	width: 100%;
	display: flex;
	position: relative;
	height: 100%;
	justify-content: center;
	align-items: center;
`;
