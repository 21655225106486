import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Button } from '../Generics/Button/Button';
import {
	FadeInHr,
	FadeInH1,
	FadeInDiv,
	FadeInButton
} from '../Generics/AnimatedComponents/FadeIn';
import { NavBar } from '../Generics/Nav/NavBar';
import {
	FadeInUpDiv,
	FadeInUpButton
} from '../Generics/AnimatedComponents/FadeInUp';
import { ColorBaseText } from '../../helpers/css_settings';
import { rxPopups } from '../../data/providers/rxPopups';
import { NewsletterPopup } from './NewsletterPopup';

const LandingBackground = styled.div`
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	overflow-y: auto;
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
`;

const LandingArea = styled.div`
	width: 100vw;
	height: 80vh;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	position: relative;
	flex-wrap: wrap;
	flex-direction: column;
`;
const OtherContent = styled(FadeInUpDiv)`
	width: 100vw;
	min-height: 50vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	overflow: hidden;
	background: #fff;
	position: relative;
	z-index: 0;
	padding-top: calc(3.5vh + 10px);
`;

const ContinueButton = styled(FadeInUpButton)`
	position: absolute;
	top: 76.5vh;
`;

const Title = styled(FadeInH1)`
	color: #fff;
	width: 100vw;
	text-align: center;
	margin: 0;
	letter-spacing: 2px;
`;

const Bar = styled(FadeInHr)`
	width: 80vw;
	border: 1px solid #fff;
	border-radius: 2px;
	max-width: 450px;
`;

const HelperText = styled(FadeInDiv)`
	color: #fff;
`;

const Section = styled(FadeInDiv)`
	display: flex;
	min-height: 200px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 10px;
	& > h1 {
		flex: 0;
	}
	& > hr {
		border-color: ${ColorBaseText};
	}
	* {
		color: ${ColorBaseText};
	}
`;
const SectionContent = styled.div`
	flex: 1;
	width: 80vw;
	max-width: 500px;
`;
const SectionTitle = styled(Title)`
	font-size: 1.3em;
	color: ${ColorBaseText};
	width: 80vw;
	max-width: 550px;
`;

export const Landing = (): ReactElement => {
	const openPopup = (): void => {
		rxPopups.next(<NewsletterPopup />);
	};

	return (
		<LandingBackground>
			<NavBar />
			<LandingArea>
				<Title animateTime={'1s'} animateDelay={'0.4s'}>
					Syrup
				</Title>
				<Bar animateTime={'1s'} animateDelay={'0.55s'} />
				<HelperText animateTime={'1s'} animateDelay={'0.7s'}>
					The worlds first automated discount service!
				</HelperText>
			</LandingArea>
			<ContinueButton
				onClick={openPopup}
				animateTime={'0.75s'}
				animateDelay={'1.5s'}
				height={'7vh'}
				width={'50vw'}
			>
				Get Started
			</ContinueButton>
			<OtherContent animateTime={'0.75s'} animateDelay={'1s'}>
				<Section>
					<SectionTitle animateTime={'0.5s'} animateDelay={'1.55s'}>
						What is Syrup?
					</SectionTitle>
					<Bar animateTime={'0.5s'} animateDelay={'1.55s'} />
					<SectionContent animateTime={'0.5s'} animateDelay={'1.55s'}>
						Syrup is a 3rd party service that acts as a middleman. We bring
						together people who wish to sell their extra unused gift cards and
						those wanting to buy. This allows us to offer you massive discounts
						you wouldn't see anywhere else.
					</SectionContent>
				</Section>
				<Section>
					<SectionTitle animateTime={'0.5s'} animateDelay={'1.55s'}>
						How does Syrup purchase items for me?
					</SectionTitle>
					<Bar animateTime={'0.5s'} animateDelay={'1.55s'} />
					<SectionContent animateTime={'0.5s'} animateDelay={'1.55s'}>
						We utalize wishlisting which allows anyone to purchase an item for
						you. With the Syrup chrome extension when you checkout, there's an
						option to choose Syrup. This will automatically create the wishlist
						for you. If you have enough credit in your account it will
						automatically be purchased. If not it will ask you to pay the
						remaining amount.
					</SectionContent>
				</Section>
				<Section>
					<SectionTitle animateTime={'0.5s'} animateDelay={'1.55s'}>
						How long do purchases take?
					</SectionTitle>
					<Bar animateTime={'0.5s'} animateDelay={'1.55s'} />
					<SectionContent animateTime={'0.5s'} animateDelay={'1.55s'}>
						As soon as payment is confirmed, the item will be purchased. Items
						with a large cost are subject to approval first.
					</SectionContent>
				</Section>
			</OtherContent>
		</LandingBackground>
	);
};
