import styled from 'styled-components';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import React from 'react';
import { Params } from '../../../helpers/params';

export interface IContentBaseProps {
	fullscreen: boolean;
}

const Content = styled.div<IContentBaseProps>`
	width: 260px;
	min-height: 260px;
	/* height: 280px; */
	max-height: ${({ fullscreen }: IContentBaseProps): string =>
		fullscreen ? 'calc(100vh - 40px)' : '320px'};
	background: #fff;
	border-radius: 10px;
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
	display: flex;
	flex-direction: column;
	overflow: auto;
	padding: 10px;
`;

interface IProps extends RouteComponentProps {
	children?: any;
}

const ContentBaseComponent = (props: IProps) => {
	const fullscreen = Params(props.location.search)?.fullscreen === 'true';
	return <Content fullscreen={!!fullscreen}>{props.children}</Content>;
};
export const ContentBase = withRouter(ContentBaseComponent);
