import { keyframes } from 'styled-components';

export const FadeIn = keyframes`
0% {
  opacity: 0;
};

100% {
  opacity: 1;
}
`;
