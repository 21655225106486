import React, { ReactElement } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Page } from '../Generics/Page/Page';
import { ContentBase } from '../Generics/Content/ContentBase';
import styled from 'styled-components';
import { Params } from '../../helpers/params';

const Title = styled.h3`
	margin: 0;
`;

const Center = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
	flex-direction: column;
`;

const CusmtomBase = styled(ContentBase)`
	height: 100%;
	justify-content: center;
	align-items: center;
	position: relative;
`;

const SubText = styled.div`
	text-align: center;
`;

const Italic = styled.i`
	/* position: absolute;
	bottom: 5px; */
	font-size: 12px;
`;

const cancelRandomizer = (): string => {
	const replies = [
		'Want to buy something else?',
		`It's okay, everyone's indecisive at some level.`,
		'Looks like the wallet won this battle.',
		'How about in that other color?'
	];
	return replies[Math.floor(Math.random() * replies.length)];
};

const PostCheckoutBase = (props: RouteComponentProps): ReactElement => {
	const text = React.useRef(cancelRandomizer());

	return (
		<Page>
			{String(Params(props.location.search)?.success).toLowerCase() ===
			'true' ? (
				<CusmtomBase>
					<Center>
						<Title>Payment Confirmed</Title>
						<SubText>
							You will recieve an email when your order is processed.*
						</SubText>
					</Center>
					<Italic>
						<b>*</b>Processing generally occurs same day, but can take up to 2
						business days.
					</Italic>
				</CusmtomBase>
			) : (
				<CusmtomBase>
					<Center>
						<Title>Checkout Cancled</Title>
						<SubText>{text.current}</SubText>
					</Center>
				</CusmtomBase>
			)}
		</Page>
	);
};

export const PostCheckout = withRouter(PostCheckoutBase);
