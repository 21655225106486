import styled from 'styled-components';
import { ColorButtonBackground1 } from '../../../helpers/css_settings';

interface IButtonProps {
	noRadius?: boolean;
	isTransparent?: boolean;
	height?: string;
	width?: string;
	isDisabled?: boolean;
}

export const Button = styled.button`
	${({ width }: IButtonProps): string =>
		!width ? `width: min-content;` : `width: ${width};`}
	max-width: 300px;
	${({ height }: IButtonProps): string =>
		!height ? `height: min-content;` : `height: ${height};`}
	${({ noRadius }: IButtonProps): string =>
		noRadius
			? 'border-radius: unset;'
			: `border-radius: 7vh;
	`}
	border: none;
	padding: 5px;
	padding-right: 10px;
	padding-left: 10px;

	z-index: 2;
	outline: none;
	transition: all 0.15s ease-in-out;
	background: ${ColorButtonBackground1};

	&:hover {
		cursor: pointer;
		box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
	}

	${({ isTransparent }: IButtonProps): string =>
		isTransparent
			? `
		background: rgba(0,0,0,0);
		&:hover{
			background: rgba(210,210,210,0.33);
		}
	`
			: ``}

	${({ isDisabled }: IButtonProps): string =>
		isDisabled
			? `
		background: rgba(0,0,0,0);
		color: rgba(210,210,210,0.33) !important; 
		&:hover{
			cursor: default;
			background: rgba(0,0,0,0);
			box-shadow: unset;
		}
	`
			: ``}
`;
