import styled from 'styled-components';

export const PopupContent = styled.div`
	padding: 20px 24px 24px 24px;
	height: max-content;
	max-height: unset;
	/* min-height: 50px; */
	/* color: rgba(0, 0, 0, 0.54); */
	flex: 1;
	font-weight: 300;
	& > i {
		font-size: 1.1em !important;
	}
`;
