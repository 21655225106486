import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { CustomRouter } from './components/Router/Router';

const urlParams = new URLSearchParams(window.location.search);
const myParam = urlParams.get('redirectkey');

// console.log('MY PARAM', myParam);
if (myParam !== null) {
	// Create the script tag to load stripe.js
	const stripeInject = document.createElement('script');
	stripeInject.setAttribute('src', 'https://js.stripe.com/v3/');
	const redirectInject = document.createElement('script');
	(window as any).redirectKey = myParam;
	redirectInject.innerHTML = `
    const loop = (index = 0) => {
      if(index >= 100) return console.log("done here");
      try {
        let stripe = new Stripe('pk_test_QKARqui7LbKCV2pxKpG9HfVe00vfnMZ3da');
        stripe.redirectToCheckout({"sessionId":window.redirectKey});
      } catch(err) {
        console.error(err);
        setTimeout(() => {loop(index + 1)}, 50);
      }
      
    }
    loop();
  `;
	document.body.append(stripeInject);
	document.body.append(redirectInject);
}

ReactDOM.render(<CustomRouter />, document.getElementById('main'));
