import React, { ReactElement } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { Landing } from '../Landing/Landing';
import {
	ColorBackgroundGradient,
	ColorBaseText
} from '../../helpers/css_settings';
import { Popups } from '../popups/Popups';
import { PostCheckout } from '../PostCheckout/PostCheckout';

const Background = styled.div`
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	background: rgb(116, 1, 196);
	background: ${ColorBackgroundGradient};
`;
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  div {
    font-size: 1em;
  }
  button {
    font-size: 1.2em;
  }
  * {
    color: ${ColorBaseText};
    font-family: 'Nunito', sans-serif
  }
`;

export const CustomRouter = (): ReactElement => {
	return (
		<Background>
			<Popups />
			<GlobalStyle />
			<BrowserRouter>
				<Switch>
					<Route exact={true} path='/stripe'>
						<PostCheckout />
					</Route>
					<Route exact={true} path='/redirect'>
						{null}
					</Route>
					<Route exact={true} path='/'>
						<Landing />
					</Route>
				</Switch>
			</BrowserRouter>
		</Background>
	);
};
