import styled from 'styled-components';
import { ICSSAnimateFunction } from '../../../data/types';
import { FadeIn } from '../Animations/FadeIn';
import { Button } from '../Button/Button';

export interface IFadeInDivProps {
	noAnimate?: boolean;
	animateTime?: string;
	animateDelay?: string;
	animateFunction?: ICSSAnimateFunction; // Animation function
}

export const FadeInDiv = styled.div`
	opacity: 0;
	transition: all
		${({ animateTime, animateFunction }: IFadeInDivProps): string =>
			(animateTime ? animateTime : '0.3s') +
			' ' +
			(animateFunction ? animateFunction : 'ease-in-out')};
	animation-fill-mode: forwards;
	animation-name: ${FadeIn};
	animation-duration: ${({ animateTime }: IFadeInDivProps): string =>
		animateTime ? animateTime : '0.3s'};
	animation-delay: ${({ animateDelay }: IFadeInDivProps): string =>
		animateDelay ? animateDelay : '0.3s'};
	animation-timing-function: ${({ animateFunction }: IFadeInDivProps): string =>
		animateFunction ? animateFunction : 'ease-in-out'};
	${({ noAnimate = false }: IFadeInDivProps): string =>
		noAnimate
			? 'transition: unset; animation-fill-mode: unset; animation: unset;'
			: ``};
`;

export const FadeInH1 = styled.h1`
	opacity: 0;
	transition: all
		${({ animateTime, animateFunction }: IFadeInDivProps): string =>
			(animateTime ? animateTime : '0.3s') +
			' ' +
			(animateFunction ? animateFunction : 'ease-in-out')};
	animation-fill-mode: forwards;
	animation-name: ${FadeIn};
	animation-duration: ${({ animateTime }: IFadeInDivProps): string =>
		animateTime ? animateTime : '0.3s'};
	animation-delay: ${({ animateDelay }: IFadeInDivProps): string =>
		animateDelay ? animateDelay : '0.3s'};
	animation-timing-function: ${({ animateFunction }: IFadeInDivProps): string =>
		animateFunction ? animateFunction : 'ease-in-out'};
	${({ noAnimate = false }: IFadeInDivProps): string =>
		noAnimate
			? 'transition: unset; animation-fill-mode: unset; animation: unset;'
			: ``};
`;
export const FadeInHr = styled.hr`
	opacity: 0;
	transition: all
		${({ animateTime, animateFunction }: IFadeInDivProps): string =>
			(animateTime ? animateTime : '0.3s') +
			' ' +
			(animateFunction ? animateFunction : 'ease-in-out')};
	animation-fill-mode: forwards;
	animation-name: ${FadeIn};
	animation-duration: ${({ animateTime }: IFadeInDivProps): string =>
		animateTime ? animateTime : '0.3s'};
	animation-delay: ${({ animateDelay }: IFadeInDivProps): string =>
		animateDelay ? animateDelay : '0.3s'};
	animation-timing-function: ${({ animateFunction }: IFadeInDivProps): string =>
		animateFunction ? animateFunction : 'ease-in-out'};
	${({ noAnimate = false }: IFadeInDivProps): string =>
		noAnimate
			? 'transition: unset; animation-fill-mode: unset; animation: unset;'
			: ``};
`;

export const FadeInButton = styled(Button)`
	opacity: 0;
	transition: all
		${({ animateTime, animateFunction }: IFadeInDivProps): string =>
			(animateTime ? animateTime : '0.3s') +
			' ' +
			(animateFunction ? animateFunction : 'ease-in-out')};
	animation-fill-mode: forwards;
	animation-name: ${FadeIn};
	animation-duration: ${({ animateTime }: IFadeInDivProps): string =>
		animateTime ? animateTime : '0.3s'};
	animation-delay: ${({ animateDelay }: IFadeInDivProps): string =>
		animateDelay ? animateDelay : '0.3s'};
	animation-timing-function: ${({ animateFunction }: IFadeInDivProps): string =>
		animateFunction ? animateFunction : 'ease-in-out'};
	${({ noAnimate = false }: IFadeInDivProps): string =>
		noAnimate
			? 'transition: unset; animation-fill-mode: unset; animation: unset;'
			: ``};
`;
