import React, { ReactElement, useState } from 'react';
import { PopupWrapper } from '../Generics/Popup/PopupWrapper';
import { PopupTitle } from '../Generics/Popup/PopupTitle';
import { PopupContent } from '../Generics/Popup/PopupContent';
import { PopupForm } from '../Generics/Popup/PopupForm';
import {
	CustomInputWrapper,
	CustomInputDescription,
	CustomInputName,
	CustomInput
} from '../Generics/Inputs/Inputs';
import { PopupActions } from '../Generics/Popup/PopupActions';
import { Button } from '../Generics/Button/Button';
import { ValidateEmail } from '../../helpers/validateEmail';
import { functions } from '../../data/providers/firebase';
import { rxPopups } from '../../data/providers/rxPopups';

export const NewsletterPopup = (): ReactElement => {
	const [email, setEmail] = useState<string>();
	const [finished, setFinished] = useState<boolean>(false);
	const [error, setError] = useState<string>();

	const updateEmail = (e: any): void => {
		setEmail(e.target.value);
	};

	const newError = (error: string): void => {
		setError(error);
		setEmail('');
		setTimeout(() => {
			setError(null);
		}, 2000);
	};

	const submitToServer = (): void => {
		newNewsletter({ email }).then(result => {
			console.log('GOT RESULT', result);

			const sanitizedMessage = result.data;
			console.log('sanitizedMessage', sanitizedMessage);
			if (sanitizedMessage.code === 200) {
				setFinished(true);
			} else {
				newError(sanitizedMessage.error);
			}
		});
	};

	const close = () => {
		rxPopups.next(null);
	};

	const newNewsletter = functions.httpsCallable('newNewsletter');

	return finished ? (
		<PopupWrapper>
			<PopupTitle>
				Success! Welcome to the humble beginnings of Syrup!
			</PopupTitle>
			<PopupContent>
				Welcome to the humble beginnings of Syrup! When closed beta comes out
				you have a chance of being selected to test the system!
			</PopupContent>
			<PopupActions>
				<Button isTransparent={true} noRadius={false} onClick={close}>
					Awesome!
				</Button>
			</PopupActions>
		</PopupWrapper>
	) : (
		<PopupWrapper>
			<PopupTitle>Sorry, we're not online yet :(</PopupTitle>
			<PopupContent>
				Not to fear! Be the first to get notified when we are up and running!
			</PopupContent>
			<PopupContent>
				<PopupForm>
					<CustomInputWrapper>
						<CustomInputName>Email</CustomInputName>
						<CustomInput onChange={updateEmail} value={email} />
						<CustomInputDescription error={error}>
							{error ? error : 'Enter your email to be notified!'}
						</CustomInputDescription>
					</CustomInputWrapper>
				</PopupForm>
			</PopupContent>
			<PopupActions>
				<Button
					isTransparent={true}
					noRadius={false}
					isDisabled={!ValidateEmail(email) || error}
					onClick={!ValidateEmail(email) || error ? () => {} : submitToServer}
				>
					Continue
				</Button>
				<Button isTransparent={true} noRadius={false} onClick={close}>
					Close
				</Button>
			</PopupActions>
		</PopupWrapper>
	);
};
