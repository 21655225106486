import * as React from 'react';
import { useEffect } from 'react';

import { rxPopups } from '../../data/providers/rxPopups';
import styled from 'styled-components';
import { FadeInDiv } from '../Generics/AnimatedComponents/FadeIn';

const GlobalPopup = styled(FadeInDiv)`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 999999999;
	& > div {
		min-height: min-content;
		min-width: min-content;
		&.mdl-dialog {
			background: #fff;
			z-index: 999999;
			.mdl-dialog__content {
				font-weight: 300;
			}
		}
		.hd-dialog__header {
			padding: 10px;
		}
	}
`;

const GlobalPopupBackground = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.5);
`;

export const Popups = (): React.ReactElement => {
	const [popupContent, setPopupContent] = React.useState();

	useEffect(() => {
		const listener = rxPopups.subscribe(setPopupContent);
		return (): void => {
			listener.unsubscribe();
		};
	}, []);

	return !!popupContent ? (
		<GlobalPopup>
			<GlobalPopupBackground />
			{React.cloneElement(popupContent, {
				style: { ...(popupContent as any)?.props?.style, zIndex: '999999' }
			})}
		</GlobalPopup>
	) : null;
};
