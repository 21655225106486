import styled from 'styled-components';
import { ICSSAnimateFunction } from '../../../data/types';
import { FadeInUp } from '../Animations/FadeInUp';
import { Button } from '../Button/Button';

export interface IFadeInUpDivProps {
	noAnimate?: boolean;
	animateTime?: string;
	animateDelay?: string;
	animateFunction?: ICSSAnimateFunction; // Animation function
}

export const FadeInUpDiv = styled.div`
	opacity: 0;
	transition: all
		${({ animateTime, animateFunction }: IFadeInUpDivProps): string =>
			(animateTime ? animateTime : '0.3s') +
			' ' +
			(animateFunction ? animateFunction : 'ease-in-out')};
	animation-fill-mode: forwards;
	animation-name: ${FadeInUp};
	animation-duration: ${({ animateTime }: IFadeInUpDivProps): string =>
		animateTime ? animateTime : '0.3s'};
	animation-delay: ${({ animateDelay }: IFadeInUpDivProps): string =>
		animateDelay ? animateDelay : '0.3s'};
	animation-timing-function: ${({
		animateFunction
	}: IFadeInUpDivProps): string =>
		animateFunction ? animateFunction : 'ease-in-out'};
	${({ noAnimate = false }: IFadeInUpDivProps): string =>
		noAnimate
			? 'transition: unset; animation-fill-mode: unset; animation: unset;'
			: ``};
`;

export const FadeInUpH1 = styled.h1`
	opacity: 0;
	transition: all
		${({ animateTime, animateFunction }: IFadeInUpDivProps): string =>
			(animateTime ? animateTime : '0.3s') +
			' ' +
			(animateFunction ? animateFunction : 'ease-in-out')};
	animation-fill-mode: forwards;
	animation-name: ${FadeInUp};
	animation-duration: ${({ animateTime }: IFadeInUpDivProps): string =>
		animateTime ? animateTime : '0.3s'};
	animation-delay: ${({ animateDelay }: IFadeInUpDivProps): string =>
		animateDelay ? animateDelay : '0.3s'};
	animation-timing-function: ${({
		animateFunction
	}: IFadeInUpDivProps): string =>
		animateFunction ? animateFunction : 'ease-in-out'};
	${({ noAnimate = false }: IFadeInUpDivProps): string =>
		noAnimate
			? 'transition: unset; animation-fill-mode: unset; animation: unset;'
			: ``};
`;
export const FadeInUpHr = styled.hr`
	opacity: 0;
	transition: all
		${({ animateTime, animateFunction }: IFadeInUpDivProps): string =>
			(animateTime ? animateTime : '0.3s') +
			' ' +
			(animateFunction ? animateFunction : 'ease-in-out')};
	animation-fill-mode: forwards;
	animation-name: ${FadeInUp};
	animation-duration: ${({ animateTime }: IFadeInUpDivProps): string =>
		animateTime ? animateTime : '0.3s'};
	animation-delay: ${({ animateDelay }: IFadeInUpDivProps): string =>
		animateDelay ? animateDelay : '0.3s'};
	animation-timing-function: ${({
		animateFunction
	}: IFadeInUpDivProps): string =>
		animateFunction ? animateFunction : 'ease-in-out'};
	${({ noAnimate = false }: IFadeInUpDivProps): string =>
		noAnimate
			? 'transition: unset; animation-fill-mode: unset; animation: unset;'
			: ``};
`;

export const FadeInUpButton = styled(Button)`
	opacity: 0;
	transition: all
		${({ animateTime, animateFunction }: IFadeInUpDivProps): string =>
			(animateTime ? animateTime : '0.3s') +
			' ' +
			(animateFunction ? animateFunction : 'ease-in-out')};
	animation-fill-mode: forwards;
	animation-name: ${FadeInUp};
	animation-duration: ${({ animateTime }: IFadeInUpDivProps): string =>
		animateTime ? animateTime : '0.3s'};
	animation-delay: ${({ animateDelay }: IFadeInUpDivProps): string =>
		animateDelay ? animateDelay : '0.3s'};
	animation-timing-function: ${({
		animateFunction
	}: IFadeInUpDivProps): string =>
		animateFunction ? animateFunction : 'ease-in-out'};
	${({ noAnimate = false }: IFadeInUpDivProps): string =>
		noAnimate
			? 'transition: unset; animation-fill-mode: unset; animation: unset;'
			: ``};
`;
