// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from 'firebase/app';

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import 'firebase/analytics';

// Add the Firebase products that you want to use
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

const firebaseConfig = {
	apiKey: 'AIzaSyC5iSTEvNKKkm1YQQ6Ygo-CvFo89foaR0o',
	authDomain: 'creativebuildsio.firebaseapp.com',
	databaseURL: 'https://creativebuildsio.firebaseio.com',
	projectId: 'creativebuildsio',
	storageBucket: 'creativebuildsio.appspot.com',
	messagingSenderId: '821207665817',
	appId: '1:821207665817:web:8405678b55e54b5233fc08'
};

firebase.initializeApp(firebaseConfig);

const functions = firebase.functions();

export { firebase, functions };
