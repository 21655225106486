import styled from 'styled-components';

export const PopupForm = styled.div`
	width: 100%;
	margin: auto;
	max-width: 80%;
	display: flex;
	max-height: 40vh;
	overflow: auto;
	overflow-x: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
`;
