import styled from 'styled-components';
export const CustomInputWrapper = styled.div`
	width: -webkit-fill-available;

	display: flex;
	align-items: center;
	flex-direction: column;
	margin-bottom: 10px;

	& > div {
		width: -webkit-fill-available;
	}
`;

export const CustomInputName = styled.div`
	width: -webkit-fill-available;
	font-size: 1.1em;
	& > i {
		font-size: 1.1em !important;
	}
`;

interface ICustomInputDescriptionProps {
	error?: boolean;
}
export const CustomInputDescription = styled.div`
	width: -webkit-fill-available;
	font-size: 0.95em;
	font-style: italic;
	color: ${({ error }: ICustomInputDescriptionProps): string =>
		error ? 'red' : 'inherit'};
`;

export const CustomTextArea = styled.textarea`
	width: -webkit-fill-available;
	font-size: 1.1em;
	max-height: 150px;
	min-height: 150px;
	resize: none;
	outline: none;
	flex: 1;
	border-color: rgb(179, 179, 179);
	border-radius: 2px;
	border-width: 1px;
`;

export const CustomInput = styled.input`
	outline: none !important;
	border-radius: 5px;
	border: 1px solid rgba(0, 0, 0, 0.3);
	padding: 5px;
	/* height: 25px; */
	font-size: 14px;
	width: -webkit-fill-available;
	&:hover {
		cursor: text;
	}
`;
