import { keyframes } from 'styled-components';

export const FadeInUp = keyframes`
  from {
    opacity: 0;
      transform: translate3d(0,40px,0)
  }

  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}
`;
