import React, {
	ReactNodeArray,
	ReactElement,
	ReactNode,
	CSSProperties
} from 'react';
import styled from 'styled-components';
import { FadeInDiv } from '../AnimatedComponents/FadeIn';
import { FadeInUpDiv } from '../AnimatedComponents/FadeInUp';

interface IProps {
	children: ReactNode | ReactNodeArray;
	style?: CSSProperties;
	size?: 'small' | 'medium' | 'large';
	id?: string;
}

interface ICustomDivProps {
	size: 'small' | 'medium' | 'large';
}

const CustomDiv = styled(FadeInUpDiv)<ICustomDivProps>`
	${({ size }: ICustomDivProps): string =>
		size === 'small'
			? `width: min-content`
			: size === 'medium'
			? 'width: 35%'
			: `width: 75%`};
	border: none;
	box-shadow: 0 9px 46px 8px rgba(0, 0, 0, 0.14),
		0 11px 15px -7px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.2);
	max-width: 800px;
	background: #fff;
	z-index: 999999;
`;

export const PopupWrapper = (props: IProps): ReactElement => {
	return (
		<CustomDiv
			className={'animated fadeInUp'}
			style={{ ...props.style }}
			size={props.size ? props.size : 'large'}
			id={props.id}
		>
			{props.children}
		</CustomDiv>
	);
};
